import { useEffect, useState } from 'react'
import { Box, Typography, Button, styled } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { navigate } from 'gatsby'
import { isEmptyObj } from 'manage-tritag/utils/string'
import Spinner from 'manage-tritag/components/loading/spinner'

const StyledBox = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        padding: '1rem',
      },
    }
  }}
  display: flex;
  margin-top: 8rem;
  align-items: center;
  flex-direction: column;
`

const NotAuthenticated = () => {
  const { user, logout } = useAuth0()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    console.log(user)
    if (user) {
      if (!isEmptyObj(user['https://tritagrugby.com/user_metadata'])) {
        navigate('/')
      }
      setIsLoading(false)
    }
  }, [user])

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 145px);',
        }}
      >
        <Spinner size={40} color="#008174" />
      </Box>
    )

  return (
    <StyledBox>
      <div>
        <div>
          <img
            src="https://tritagrugbypublic.s3.ap-southeast-2.amazonaws.com/logo.png"
            alt="logo"
            style={{
              maxWidth: 200,
              marginBottom: '1.5rem',
            }}
          />
        </div>
        <Typography variant="h4" style={{ marginBottom: '2rem' }}>
          Unauthorised
        </Typography>
        <Typography variant="h5" style={{ marginBottom: '2rem' }}>
          You do not have the required user permissions to access
          manage.tritagrugby.com
        </Typography>
        <Typography variant="h5" style={{ marginBottom: '2rem' }}>
          Please contact your competition administrator to be granted access.
        </Typography>

        <div>
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <Button
              variant="contained"
              color="primary"
              className="montserrat"
              style={{ margin: 'auto', width: '50%' }}
              onClick={() => logout()}
            >
              LOG OUT
            </Button>
          </div>
        </div>
      </div>
    </StyledBox>
  )
}

export default NotAuthenticated
